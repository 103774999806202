<template>
  <div v-if="asset.type === 'PDF'" class="container">
    <div class="meta">
      <div class="thumbnail">
        <PDFThumbnail />
      </div>
      <div class="title">
        <h1 class="type">{{ asset.type }}</h1>
        <h1 :class="{ italic: asset.title === '' }" class="name">{{ asset.title || 'No Title' }}</h1>
      </div>
    </div>
    <div v-if="tag === 'Participant'" v-html="asset.participantDescription" class="description"></div>
    <div v-else-if="tag === 'Instructor'" v-html="asset.instructorDescription" class="description"></div>
    <div @click="download(asset.assetURL)" class="primary-btn">
      Download PDF
    </div>
  </div>
  <div v-else-if="asset.type === 'Slideshow'" class="container">
    <div class="meta">
      <div class="thumbnail">
        <SlideshowThumbnail />
      </div>
      <div class="title">
        <h1 class="type">{{ asset.type }}</h1>
        <h1 :class="{ italic: asset.title === '' }" class="name">{{ asset.title || 'No Title' }}</h1>
      </div>
    </div>
    <div v-if="tag === 'Participant'" v-html="asset.participantDescription" class="description"></div>
    <div v-else-if="tag === 'Instructor'" v-html="asset.instructorDescription" class="description"></div>
    <div @click="download(asset.assetURL)" class="primary-btn">
      Download Slideshow
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PDFThumbnail from '@/assets/icons/pdf_thumbnail.svg';
import SlideshowThumbnail from '@/assets/icons/slideshow_thumbnail.svg';

export default {
  name: 'pdf',
  props: ['asset', 'tag'],
  components: {
    PDFThumbnail,
    SlideshowThumbnail,
  },
  created() {
    this.getThumbnail();
  },
  updated() {
    this.getThumbnail();
  },
  methods: {
    getThumbnail() {
      if (!this.asset.filePath.startsWith('media/')) {
        this.src = this.asset.filePath;
      } else {
        axios
          .get(`${process.env.VUE_APP_API_URL}/assets/${this.asset.id}`, {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          })
          .then((response) => {
            // handle success
            // if (response.data.data.thumbnailUrl) {
            //   this.poster = response.data.data.thumbnailUrl;
            // }
            this.src = response.data.data.signedUrl;
          })
          .catch((error) => {
            // handle error
            console.error(error);
          });
      }
    },
    download() {
      if (!this.asset.filePath.startsWith('media/')) {
        window.open(this.asset.filePath, '_blank');
      } else {
        axios
          .get(`${process.env.VUE_APP_API_URL}/assets/${this.asset.id}`, {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          })
          .then((response) => {
            // handle success
            // need to call this on click because signedUrl expires after 5min
            this.src = response.data.data.signedUrl;
            window.open(response.data.data.signedUrl, '_blank');
          })
          .catch((error) => {
            // handle error
            console.error(error);
            window.open(this.src, '_blank');
          });
      }
    },
  },
  data() {
    return {
      src: '',
      poster: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .meta {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    margin-bottom: 25px !important;
    .thumbnail {
      width: 100%;
      height: auto !important;
      // margin-right: 20px !important;
      @include md {
        margin-right: 20px !important;
        width: 125px;
      }
      > svg {
        width: 100% !important;
        height: auto !important;
      }
    }
    .type {
      font-family: $head-font-stack !important;
      font-size: 2rem !important;
      font-weight: 300 !important;
      margin: 0 !important;
    }
    .name {
      font-weight: 700 !important;
      font-size: 1.4rem !important;
    }
  }
  .description {
    font-family: $text-font-stack !important;
    font-weight: 300 !important;
    font-size: 1.4rem !important;
    margin-bottom: 25px;
    p {
      font-weight: 300 !important;
    }
  }
}
</style>
