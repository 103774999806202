<template>
  <div v-if="course" class="preview">
    <div class="top-bar">
      <div class="text">
        <span>You are viewing as:</span>
        <Multiselect
          v-model="tag"
          :options="options"
          :show-labels="false"
          :searchable="false"
          :allow-empty="false"
        ></Multiselect>
      </div>
      <div class="views">
        <div class="view desktop" @click="view = 'desktop'">
          <DesktopSVG v-if="view === 'desktop'" />
          <DesktopWhiteSVG v-else />
        </div>
        <div class="view tablet" @click="view = 'tablet'">
          <TabletSVG v-if="view === 'tablet'" />
          <TabletWhiteSVG v-else />
        </div>
        <div class="view mobile" @click="view = 'mobile'">
          <MobileSVG v-if="view === 'mobile'" />
          <MobileWhiteSVG v-else />
        </div>
      </div>
    </div>
    <div class="content">
      <div
        class="content-inner"
        :class="[
          view === 'mobile' ? 'mobile' : '',
          view === 'tablet' ? 'tablet' : '',
          view === 'desktop' ? 'desktop' : '',
          notesOpen ? 'notesOpen' : '',
          !notesOpen && editMode === 'section' ? 'notesClosed' : '',
        ]"
      >
        <div v-if="view === 'desktop'" class="desktop-meta">
          <div class="course-meta">
            <div class="inner">
              <div v-for="(m, moduleIndex) in course.modules" :key="moduleIndex" class="modules">
                <div
                  class="module"
                  :class="{ active: editMode === 'module' && moduleBeingShown === moduleIndex }"
                  @click="editModule(moduleIndex)"
                >
                  <span> {{ m.name }}</span>
                </div>
                <!-- sections -->
                <div v-if="moduleIndex === moduleBeingShown" class="sections">
                  <div
                    class="section"
                    v-for="(section, sectionIndex) in course.modules[moduleBeingShown].sections"
                    :key="sectionIndex"
                    @click="editsection(sectionIndex)"
                    :class="{
                      active:
                        editMode === 'section' &&
                        moduleBeingShown === moduleIndex &&
                        sectionBeingShown === sectionIndex,
                    }"
                  >
                    <span> {{ section.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="left"
          :class="[
            rightOpened && view !== 'mobile' ? 'rightOpened' : '',
            view === 'mobile' ? 'fullWidth' : '',
            isToolkit ? 'toolkit' : '',
          ]"
        >
          <div v-if="course.livestreamService === 'adobe connect'" class="livestream">
            <!-- <iframe
              v-if="isIE11"
              :src="`${course.livestreamLink}?launcher=false`"
              name="Demonstrating your impact with the h-index"
              height="500"
              width="100%"
              align="left"
              scrolling="no"
              frameborder="0"
            ></iframe> -->
            <div v-if="isIE11" class="ie">
              <h1>The Adobe Connect stream should be open in a new window.</h1>
              <h2 @click="reopen">Reopen</h2>
            </div>
            <iframe
              v-else
              :src="`${course.livestreamLink}?launcher=false`"
              name="Demonstrating your impact with the h-index"
              height="500"
              width="100%"
              align="left"
              scrolling="no"
              frameborder="0"
              sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-presentation allow-same-origin allow-scripts"
            ></iframe>
          </div>
          <div v-else-if="showToolkitMeta" class="toolkit" :class="{ column: view === 'mobile' }">
            <ToolkitIcon />
            <div class="name">
              <div class="name">{{ activeCourse.name }}</div>
              <div class="toolkit">MODULAR TRAINING TOOLKIT</div>
            </div>
          </div>
          <div class="below-livestream">
            <div
              v-if="view !== 'desktop' && !(view === 'mobile' && showRightContent)"
              class="course-meta"
              :class="[
                view === 'mobile' && !showMobile ? 'mobile' : '',
                view === 'mobile' && showMobile ? 'expanded' : '',
              ]"
            >
              <div v-if="view === 'mobile' && showMobile" class="back">
                <CaratSVG :class="{ flip: showMobile }" @click="showMobile = !showMobile" />
              </div>
              <div v-if="view !== 'mobile' || showMobile === true" class="inner">
                <div
                  v-if="!activeCourse.livestreamService || activeCourse.livestreamService !== 'adobe connect'"
                  class="toolkit module"
                  :class="{ active: showToolkitMeta }"
                  @click="showToolkitMeta = !showToolkitMeta"
                >
                  <span>{{ activeCourse.name }}</span>
                </div>
                <div v-for="(m, moduleIndex) in course.modules" :key="moduleIndex" class="modules">
                  <div
                    class="module"
                    :class="{
                      active:
                        editMode === 'module' && moduleBeingShown === moduleIndex && (!showToolkitMeta || !isToolkit),
                    }"
                    @click="editModule(moduleIndex)"
                  >
                    <span> {{ m.name }}</span>
                  </div>
                  <!-- sections -->
                  <div v-if="moduleIndex === moduleBeingShown" class="sections">
                    <div
                      class="section"
                      v-for="(section, sectionIndex) in course.modules[moduleBeingShown].sections"
                      :key="sectionIndex"
                      @click="editsection(sectionIndex)"
                      :class="{
                        active:
                          editMode === 'section' &&
                          moduleBeingShown === moduleIndex &&
                          sectionBeingShown === sectionIndex &&
                          !showToolkitMeta,
                      }"
                    >
                      <span> {{ section.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="view === 'mobile' && !showMobile" class="inner-mobile">
                <HamburgerSVG @click="showMobile = !showMobile" />
              </div>
            </div>
            <div
              v-if="!(view === 'mobile' && showMobile) && !(view === 'mobile' && showRightContent)"
              class="course-content"
              :class="{ 'mobile-toolkit': view === 'mobile' && isToolkit }"
            >
              <!-- module mode -->
              <div v-if="editMode === 'module' && (!showToolkitMeta || !isToolkit)" class="module">
                <h1 class="name">{{ activeCourse.modules[moduleBeingShown].name }}</h1>
                <div v-html="course.modules[moduleBeingShown].description" class="description"></div>
              </div>
              <!-- section mode -->
              <div v-else-if="editMode === 'section' && (!showToolkitMeta || !isToolkit)" class="section">
                <div
                  v-for="(asset, assetIndex) in course.modules[moduleBeingShown].sections[sectionBeingShown].assets"
                  :key="assetIndex"
                  class="asset"
                >
                  <div v-if="asset.type === 'Web-Based Training' || asset.type === 'Link'" class="meta">
                    <div class="thumbnail">
                      <WBTThumbnail v-if="asset.type === 'Web-Based Training'" />
                      <LinkThumbnail v-else-if="asset.type === 'Link'" />
                    </div>
                    <div class="title">
                      <h1 class="type">{{ asset.type }}</h1>
                      <h1 :class="{ italic: asset.title === '' }" class="name">{{ asset.title || 'No Title' }}</h1>
                    </div>
                  </div>
                  <div class="fullWidth" v-else-if="asset.type === 'Image' || asset.type === 'Video'">
                    <SignedImage :asset="asset" />
                    <h1 v-if="asset.title && asset.title.length" :class="{ italic: asset.title === '' }" class="name">
                      {{ asset.title }}
                    </h1>
                  </div>
                  <div v-else-if="asset.type === 'Divider'" class="divider"></div>
                  <div
                    v-if="tag === 'Participant' && asset.type !== 'PDF' && asset.type !== 'Slideshow'"
                    v-html="
                      course.modules[moduleBeingShown].sections[sectionBeingShown].assets[assetIndex]
                        .participantDescription
                    "
                    class="description"
                  ></div>
                  <div
                    v-else-if="tag === 'Instructor' && asset.type !== 'PDF' && asset.type !== 'Slideshow'"
                    v-html="
                      course.modules[moduleBeingShown].sections[sectionBeingShown].assets[assetIndex]
                        .instructorDescription
                    "
                    class="description"
                  ></div>
                  <div v-if="asset.type === 'Web-Based Training' || asset.type === 'Link'" class="launch">
                    <div v-if="asset.type === 'Web-Based Training'" @click="goTo(asset.assetURL)" class="primary-btn">
                      Launch Web-Based Training
                    </div>
                    <div v-if="asset.type === 'Link'" @click="goTo(asset.assetURL)" class="primary-btn">
                      Visit Website
                    </div>
                  </div>
                  <PDFThumbnail
                    v-else-if="asset.type.toUpperCase() === 'PDF' || asset.type.toUpperCase() === 'SLIDESHOW'"
                    :asset="asset"
                    :tag="tag"
                  />
                </div>
              </div>
              <!-- Toolkit info -->
              <div v-else-if="showToolkitMeta && isToolkit" class="toolkit-info">
                <div class="for">
                  <h1>Who This Course is For</h1>
                  <p class="description">{{ getRoles(activeCourse.roles).join(', ') || 'All Roles' }}</p>
                </div>
                <div class="row">
                  <div class="instructors">
                    <h1>Instructors</h1>
                    <p class="description">{{ getInstructors(activeCourse.instructors).join(', ') || '—' }}</p>
                  </div>
                  <div class="date">
                    <h1>Date</h1>
                    <p v-if="activeCourse.date" class="description">
                      {{ getMomentTime(activeCourse.date) }}
                    </p>
                    <p v-else-if="activeCourse.startDate" class="description">
                      {{ getMomentTime(activeCourse.startDate) }}
                    </p>
                  </div>
                  <diV class="courseNum">
                    <h1>Course</h1>
                    <p class="description">{{ activeCourse.courseNumber || '—' }}</p>
                  </diV>
                  <div class="prereqs">
                    <h1>Prerequisites</h1>
                    <p v-if="activeCourse.prerequisites" class="description">
                      <!-- {{ getPrereqs(activeCourse.prerequisites).join(', ') || '—' }} -->
                      {{ activeCourse.prerequisites.join(', ') || '—' }}
                    </p>
                    <p v-else-if="activeCourse.prereqs" class="description">
                      <!-- {{ getPrereqs(activeCourse.prereqs).join(', ') || '—' }} -->
                      {{ activeCourse.prereqs.join(', ') || '—' }}
                    </p>
                  </div>
                </div>
                <div class="equipment">
                  <h1>Required Equipment</h1>
                  <ul class="description">
                    <li v-for="(equipment, index) in activeCourseEquipment" :key="index">
                      {{ equipment.name }}
                      <span v-if="equipment.required !== 'YES'" class="preferred">Preferred</span>
                    </li>
                    <p v-if="!activeCourseEquipment || !activeCourseEquipment.length" class="description">—</p>
                  </ul>
                </div>
                <div v-if="activeCourse.description" class="courseDescription">
                  <h1>Description</h1>
                  <p class="description" v-html="activeCourse.description"></p>
                </div>
              </div>
            </div>
            <div
              v-if="view === 'mobile' && !showMobile && course.livestreamService"
              class="right mobile"
              :class="{ opened: rightOpened }"
              @click="toggleRight"
            >
              <div v-if="showRightContent" class="right-inner">
                <div class="title">
                  <span>{{ course.name }}</span>
                  <div class="svg">
                    <CaratSVG />
                  </div>
                </div>
                <div
                  class="type"
                  :class="[
                    getType(course.livestreamService) === 'Virtual Classroom' ? 'bright' : '',
                    getType(course.livestreamService) === 'Modular Training Toolkit' ? 'red' : '',
                  ]"
                >
                  {{ getType(course.livestreamService) }}
                </div>
                <div class="for">
                  <h1>Who This Course is For</h1>
                  <p class="description">{{ getRoles(course.roles).join(', ') || 'All Roles' }}</p>
                </div>
                <div class="instructors">
                  <h1>Instructors</h1>
                  <p class="description">{{ getInstructors(course.instructors).join(', ') || '—' }}</p>
                </div>
                <div class="date">
                  <h1>Date</h1>
                  <p v-if="course.date" class="description">
                    {{ getMomentTime(course.date) }}
                  </p>
                  <p v-else-if="course.startDate" class="description">
                    {{ getMomentTime(course.startDate) }}
                  </p>
                </div>
                <diV class="courseNum">
                  <h1>Course</h1>
                  <p class="description">{{ course.courseNumber || '—' }}</p>
                </diV>
                <div class="prereqs">
                  <h1>Prerequisites</h1>
                  <p v-if="course.prerequisites" class="description">
                    <!-- {{ getPrereqs(course.prerequisites).join(', ') || '—' }} -->
                    {{ course.prerequisites.join(', ') || '—' }}
                  </p>
                  <p v-else-if="course.prereqs" class="description">
                    <!-- {{ getPrereqs(course.prereqs).join(', ') || '—' }} -->
                    {{ course.prereqs.join(', ') || '—' }}
                  </p>
                </div>
                <div class="equipment">
                  <h1>Required Equipment</h1>
                  <ul class="description">
                    <li v-for="(equipment, index) in activeCourseEquipment" :key="index">
                      {{ equipment.name }}
                      <span v-if="equipment.required !== 'YES'" class="preferred">Preferred</span>
                    </li>
                    <p v-if="!activeCourseEquipment.length" class="description">—</p>
                  </ul>
                </div>
                <div v-if="course.description" class="courseDescription">
                  <h1>Description</h1>
                  <p class="description" v-html="course.description"></p>
                </div>
              </div>
              <div v-else class="svg"><CaratSVG /></div>
            </div>
          </div>
        </div>
        <div
          v-if="view !== 'mobile' && course.livestreamService"
          class="right"
          :class="{ opened: rightOpened }"
          @click="toggleRight"
        >
          <div v-if="showRightContent" class="right-inner">
            <div class="title">
              <span>{{ course.name }}</span>
              <div class="svg">
                <CaratSVG />
              </div>
            </div>
            <div
              class="type"
              :class="[
                getType(course.livestreamService) === 'Virtual Classroom' ? 'bright' : '',
                getType(course.livestreamService) === 'Modular Training Toolkit' ? 'red' : '',
              ]"
            >
              {{ getType(course.livestreamService) }}
            </div>
            <div class="for">
              <h1>Who This Course is For</h1>
              <p class="description">{{ getRoles(course.roles).join(', ') || 'All Roles' }}</p>
            </div>
            <div class="instructors">
              <h1>Instructors</h1>
              <p class="description">{{ getInstructors(course.instructors).join(', ') || '—' }}</p>
            </div>
            <div class="date">
              <h1>Date</h1>
              <p v-if="course.date" class="description">
                {{ getMomentTime(course.date) }}
              </p>
              <p v-else-if="course.startDate" class="description">
                {{ getMomentTime(course.startDate) }}
              </p>
            </div>
            <diV class="courseNum">
              <h1>Course</h1>
              <p class="description">{{ course.courseNumber || '—' }}</p>
            </diV>
            <div class="prereqs">
              <h1>Prerequisites</h1>
              <p v-if="course.prerequisites" class="description">
                <!-- {{ getPrereqs(course.prerequisites).join(', ') || '—' }} -->
                {{ course.prerequisites.join(', ') || '—' }}
              </p>
              <p v-else-if="course.prereqs" class="description">
                <!-- {{ getPrereqs(course.prereqs).join(', ') || '—' }} -->
                {{ course.prereqs.join(', ') || '—' }}
              </p>
            </div>
            <div class="equipment">
              <h1>Required Equipment</h1>
              <ul class="description">
                <li v-for="(equipment, index) in activeCourseEquipment" :key="index">
                  {{ equipment.name }}
                  <span v-if="equipment.required !== 'YES'" class="preferred">Preferred</span>
                </li>
                <p v-if="!activeCourseEquipment.length" class="description">—</p>
              </ul>
            </div>
            <div v-if="course.description" class="courseDescription">
              <h1>Description</h1>
              <p class="description" v-html="course.description"></p>
            </div>
          </div>
          <div v-else class="svg"><CaratSVG /></div>
        </div>
      </div>
      <div v-if="tag === 'Participant' && editMode === 'section'" class="notes" :class="{ mobile: $mq === 'sm' }">
        <div
          class="notes-inner"
          :class="[
            view === 'mobile' ? 'mobile' : '',
            view === 'tablet' ? 'tablet' : '',
            view === 'desktop' ? 'desktop' : '',
            notesOpen ? 'opened' : '',
            !notesOpen || editMode === 'section' ? 'notesOpen' : '',
          ]"
        >
          <div class="notes-inner-top">
            <div
              class="meta"
              :class="{ italic: activeCourse.modules[moduleBeingShown].sections[sectionBeingShown].name === '' }"
            >
              Section Notes |
              {{ activeCourse.modules[moduleBeingShown].sections[sectionBeingShown].name || 'No Title' }}
            </div>
            <CaratSVG :class="{ opened: notesOpen }" @click="notesOpen = !notesOpen" />
          </div>
          <div v-if="notesOpen" class="notes-inner-bottom">
            <!-- <div class="text"> -->
            <div class="wysiwyg">
              <ckeditor v-model="participantNotes" :config="editorConfig"></ckeditor>
              <p>Take notes and upload photos to this section. These will only be visible to you.</p>
            </div>
            <!-- </div> -->
            <div class="assets">
              <h3>Upload Images</h3>
              <p>You can’t upload images in preview mode.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaratSVG from '@/assets/icons/carat_black.svg';
import DesktopSVG from '@/assets/icons/desktop.svg';
import TabletSVG from '@/assets/icons/tablet.svg';
import MobileSVG from '@/assets/icons/mobile.svg';
import DesktopWhiteSVG from '@/assets/icons/desktop_white.svg';
import TabletWhiteSVG from '@/assets/icons/tablet_white.svg';
import MobileWhiteSVG from '@/assets/icons/mobile_white.svg';
import PDFThumbnail from '@/components/PDFThumbnail.vue';
import WBTThumbnail from '@/assets/icons/wbt_thumbnail.svg';
import HamburgerSVG from '@/assets/icons/hamburger.svg';
// import SlideshowThumbnail from '@/assets/icons/slideshow_thumbnail.svg';
import LinkThumbnail from '@/assets/icons/link_thumbnail.svg';
import ToolkitIcon from '@/assets/icons/vw_academy_logo.svg';

import SignedImage from '@/components/Thumbnail.vue';

import moment from 'moment';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Multiselect from 'vue-multiselect';

import Vue from 'vue';

import CKEditor from 'ckeditor4-vue/dist/legacy';

Vue.use(CKEditor);

export default {
  name: 'Preview-New-Course',
  props: ['equipment'],
  computed: {
    ...mapGetters(['activeCourse', 'instructors', 'courses', 'activeCourseEquipment']),
    activeSession() {
      return this.activeCourse.modules[this.moduleBeingShown].sections[this.sectionBeingShown];
    },
    isToolkit() {
      if (!this.activeCourse.livestreamService || this.activeCourse.livestreamService !== 'adobe connect') {
        return true;
      }
      return false;
    },
  },
  components: {
    CaratSVG,
    Multiselect,
    DesktopSVG,
    TabletSVG,
    MobileSVG,
    DesktopWhiteSVG,
    TabletWhiteSVG,
    MobileWhiteSVG,
    PDFThumbnail,
    WBTThumbnail,
    // SlideshowThumbnail,
    LinkThumbnail,
    SignedImage,
    HamburgerSVG,
    ToolkitIcon,
  },
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/courses/${this.activeCourse.id}`, {
        headers: { authorization: sessionStorage.getItem('vwcup-token') },
      })
      .then((response) => {
        // handle success
        this.course = response.data.data.course;
        // return response.data.data.signedUrl;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  },
  mounted() {
    // if (this.activeCourse === null) {
    //   this.$router.push('/create-course');
    // }
    if (!this.activeCourse.livestreamService || this.activeCourse.livestreamService !== 'adobe connect') {
      this.showToolkitMeta = true;
    }
    if (this.isIE11 && !this.isToolkit) {
      this.reopen();
    }
  },
  methods: {
    toggleRight() {
      this.rightOpened = !this.rightOpened;
      if (this.showRightContent) {
        this.showRightContent = false;
      } else {
        setTimeout(() => {
          this.showRightContent = true;
        }, 300);
      }
    },
    getType(service) {
      if (service && service.length) {
        return 'Virtual Classroom';
      }
      return 'Modular Training Toolkit';
    },
    getRoles(roles) {
      const x = [];
      if (roles) {
        roles.forEach((role) => {
          x.push(role.name);
        });
      }
      return x;
    },
    getInstructors(instructors) {
      const x = [];
      if (instructors && this.instructors) {
        instructors.forEach((instructor) => {
          if (typeof instructor !== 'object') {
            const y = this.instructors.findIndex((ins) => ins.id.toString() === instructor.toString());
            if (y > -1) {
              x.push(`${this.instructors[y].firstName} ${this.instructors[y].lastName}`);
            }
          } else if (typeof instructor === 'object') {
            const y = this.instructors.findIndex((ins) => ins.id.toString() === instructor.userID.toString());
            if (y > -1) {
              x.push(`${this.instructors[y].firstName} ${this.instructors[y].lastName}`);
            }
          }
        });
      }
      return x;
    },
    getMomentTime(time) {
      if (!time || time === '0000-00-00 00:00:00') {
        return '—';
      }
      return moment.utc(time).format('MM-DD-yy');
    },
    goTo(url) {
      window.open(url, '_blank');
    },
    download(url) {
      console.error(url);
    },
    editModule(index) {
      this.showMobile = false;
      this.editMode = 'module';
      this.moduleBeingShown = index;
      this.sectionBeingShown = 0;
      this.showToolkitMeta = false;
    },
    editsection(index) {
      this.showMobile = false;
      this.editMode = 'section';
      this.sectionBeingShown = index;
      this.showToolkitMeta = false;
    },
    reopen() {
      window.open(this.activeCourse.livestreamLink, '_blank');
    },
  },
  data() {
    return {
      isIE11: !!window.MSInputMethodContext && !!document.documentMode,
      showToolkitMeta: true,
      showMobile: false,
      editMode: 'module',
      course: null,
      options: ['Participant', 'Instructor'],
      tag: 'Participant',
      view: 'tablet',
      rightOpened: false,
      showRightContent: false,
      moduleBeingShown: 0,
      sectionBeingShown: 0,
      notesOpen: false,
      participantNotes: 'You can’t type notes in preview mode.',
      // wysiwyg config
      editorConfig: {
        height: 100,
        toolbarGroups: [
          { name: 'clipboard', groups: ['undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'basicstyles', groups: ['bold, italic'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'align'],
          },
        ],
        removeButtons: 'Cut,Copy,Paste,Underline,Strike,Subscript,Superscript,PasteFromWord,PasteText,Styles',
        removePlugins: 'PasteFromWord,',
        pasteAsPlainText: false,
        readonly: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.preview {
  min-height: 100vh;
  width: 100vw;
  .top-bar {
    background: $primary-dark;
    height: 93px;
    display: flex;
    align-items: center;
    .text {
      display: flex;
      align-items: center;
      margin: 0 75px;
      span {
        font-size: 1.4rem;
        font-weight: 700;
        color: $primary-white;
        flex: auto;
        margin-right: 15px;
      }
      .multiselect {
        width: 160px;
      }
    }
    .views {
      display: flex;
      align-items: center;
      margin: 0 75px 0 auto;
      .view {
        color: $primary-white;
        cursor: pointer;
        margin: 0 10px;
        &.desktop {
          > svg {
            width: 53px;
            height: 50px;
          }
        }
        &.tablet {
          > svg {
            width: 35px;
            height: 44px;
          }
        }
        &.mobile {
          > svg {
            width: 21px;
            height: 35px;
          }
        }
      }
    }
  }
}
// ::v-deep {
.multiselect {
  height: 32px;
  background: $primary-white;
  &__select {
    &::before {
      background-image: url('~@/assets/icons/carat_black.svg?external');
      background-size: 16px 8px;
      border: 0;
      display: block;
      top: 8px;
      left: 4px;
      width: 16px;
      height: 8px;
    }
  }
  &__tags {
    background-color: $primary-white;
    border-radius: 3px;
    padding-top: 10px;
    min-height: 40px !important;
  }
  &__tag {
    background: $primary-light;
    padding: 4px 20px 4px 10px;

    i {
      &::after {
        color: white;
      }
      &:hover {
        background: $primary-light;
      }
    }
  }
  &__input,
  &__single {
    display: block;
  }
  &__content-wrapper {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    min-width: 100%;
    width: auto;
  }
  &__option--highlight {
    background: $primary-light;
    &::after {
      background: $primary-light;
    }
  }
  // }
}

.content {
  // min-height: calc(100vh - 93px);
  // margin-top: 50px;
  // margin-bottom: 50px;
  padding: 0 !important;
  width: 100vw;
  .content-inner {
    margin: auto;
    // display: flex;
    box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.29);
    min-height: calc(100vh - 93px);
    // margin-bottom: 12vh;
    &.desktop {
      // width: calc(100vw);
      display: flex;
      width: 100vw;
      // height: calc((100vw) * 0.96);
      &.notesOpen {
        margin-bottom: 280px !important;
      }
      &.notesClosed {
        margin-bottom: 100px !important;
      }
      .below-livestream {
        // flex: 1;
        // height: 100%;
      }
    }
    &.tablet {
      margin-top: 50px;
      width: calc(70vw);
      // height: calc((70vw) * 1.33);
      display: flex;
      &.notesOpen {
        margin-bottom: 268px !important;
      }
      .below-livestream {
        // flex: 1;
        // height: 100%;
      }
    }
    &.mobile {
      margin-top: 50px;
      width: calc(30vw);
      // height: calc((30vw) * 2.16);
      &.notesOpen {
        margin-bottom: 500px !important;
      }
    }
    &.notesClosed {
      margin-bottom: 80px;
    }
    .desktop-meta {
      // flex: 1;
      background: $primary-white;
      width: 18%;
      box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.29);
      &.mobile {
        max-width: 40px;
        // transition: max-width 0.3s ease-out;
        .inner-mobile {
          width: 100%;
          text-align: center;
          svg {
            margin-top: 15px;
            height: 8px;
            width: 20px;
            cursor: pointer;
            ::v-deep {
              g {
                stroke: $primary-black;
              }
            }
          }
        }
      }
      &.expanded {
        max-width: 100%;
        // transition: max-width 0.3s ease-in;
      }
      .modules {
        .module {
          &.toolkit {
            border-bottom: 2px solid $danger;
            font-size: 1.4rem;
            display: flex;
            height: 56px;
            align-items: center;
          }
          font-family: $head-font-stack;
          font-weight: 300;
          font-size: 1.2rem;
          cursor: pointer;
          min-height: 35px;
          display: flex;
          align-items: center;
          &.active {
            background: rgba(0, 176, 240, 0.4);
            font-weight: 700;
          }
          > span {
            padding: 10px 20px;
            width: calc(100% - 40px);
            // word-wrap: break-word;
          }
        }
        > .sections {
          > .section {
            font-family: $head-font-stack;
            font-weight: 300;
            font-size: 1.2rem;
            padding: 0 20px 0 32px;
            cursor: pointer;
            min-height: 35px;
            display: flex;
            align-items: center;
            &.active {
              background: rgba(0, 176, 240, 0.4);
              font-weight: 700;
            }
            > span {
              // padding-left: 20px;
              padding: 10px 0px;
              width: calc(100% - 40px);
              // word-wrap: break-word;
            }
          }
        }
      }
      .back {
        svg {
          transform: rotate(90deg);
          width: 15px;
          height: 15px;
          transition: transform 0.3s ease-out;
          margin: 20px;
          cursor: pointer;
          &.flip {
            rotate: (-90deg);
            transition: transform 0.3s ease-in;
          }
        }
      }
    }
    .left {
      &.toolkit {
        width: 100%;
        .below-livestream {
          min-height: 100vh;
        }
      }
      width: calc(100% - 50px);
      height: 100%;
      min-height: calc(100vh - 93px);
      transition: width 0.3s ease-in-out;
      overflow-y: scroll;
      overflow-x: hidden !important;
      display: flex;
      flex-direction: column;
      &.fullWidth {
        width: 100%;
      }
      .livestream {
        width: 101%;
        min-height: 500px;
        // overflow-x: hidden !important;
        .ie {
          background: $grey;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 500px;
          width: 100%;
          h1 {
            font-weight: 700;
            font-size: 1.8rem;
            width: 80%;
            text-align: center;
          }
          h2 {
            font-size: 1.6rem;
            color: $primary-dark;
            cursor: pointer;
          }
        }
        iframe {
          // background: red;
        }
      }
      > .toolkit {
        height: 192px !important;
        border-bottom: 8px solid $danger;
        background: $primary-dark;
        display: flex;
        align-items: flex-end;
        &.column {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          > svg {
            margin-bottom: 5px;
            margin-top: 15px;
            margin-left: 50px;
          }
          > .name {
            margin-left: 38px;
          }
        }
        svg {
          height: 50px;
          width: 50px;
          margin-left: 50px;
          margin-bottom: 28px;
        }
        .name {
          // height: 40%;
          font-family: $head-font-stack;
          color: $primary-white;
          font-size: 2.8rem;
          font-weight: 300;
          .name {
            margin-left: 12px;
          }
          .toolkit {
            font-family: $text-font-stack;
            font-weight: 700;
            color: $danger;
            font-size: 1.2rem;
            margin-left: 12px;
            margin-bottom: 25px !important;
            margin-top: 5px;
          }
        }
      }
      &.rightOpened {
        width: 60%;
        transition: width 0.3s ease-in-out;
      }
      .below-livestream {
        // flex: 1;
        display: flex;
        // min-height: 90vh;
        // height: 100%;
        .course-meta {
          flex: 1;
          // width: 18%;
          // box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.29);
          background: $primary-white;
          &.mobile {
            max-width: 40px;
            transition: max-width 0.3s ease-out;
            .inner-mobile {
              width: 100%;
              text-align: center;
              svg {
                margin-top: 15px;
                height: 13px;
                width: 20px;
                cursor: pointer;
                ::v-deep {
                  g {
                    stroke: $primary-black;
                  }
                }
              }
            }
          }
          &.expanded {
            max-width: 100%;
            transition: max-width 0.3s ease-in;
          }
          .modules {
            .module {
              &.toolkit {
                border-bottom: 2px solid $danger;
                font-size: 1.4rem;
                display: flex;
                height: 56px;
                align-items: center;
              }
              font-family: $head-font-stack;
              font-weight: 300;
              font-size: 1.2rem;
              cursor: pointer;
              min-height: 35px;
              display: flex;
              align-items: center;
              &.active {
                background: rgba(0, 176, 240, 0.4);
                font-weight: 700;
              }
              > span {
                padding: 10px 20px;
                width: calc(100% - 40px);
                // word-wrap: break-word;
              }
            }
            > .sections {
              > .section {
                font-family: $head-font-stack;
                font-weight: 300;
                font-size: 1.2rem;
                padding: 0 20px 0 32px;
                cursor: pointer;
                min-height: 35px;
                display: flex;
                align-items: center;
                &.active {
                  background: rgba(0, 176, 240, 0.4);
                  font-weight: 700;
                }
                > span {
                  padding: 10px 0px;
                  width: calc(100% - 40px);
                  // word-wrap: break-word;
                }
              }
            }
          }
          .back {
            svg {
              transform: rotate(90deg);
              width: 15px;
              height: 15px;
              transition: transform 0.3s ease-out;
              margin: 20px;
              cursor: pointer;
              &.flip {
                rotate: (-90deg);
                transition: transform 0.3s ease-in;
              }
            }
          }
        }
        .course-content {
          box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.29);
          flex: 3;
          width: calc(100% - 40px);
          > * {
            margin: 50px;
          }
          &.mobile-toolkit {
            > * {
              margin: 50px 20px;
            }
          }
          .asset {
            // width: 100%;
            margin-bottom: 50px;
            .meta {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              margin-bottom: 20px;
              .thumbnail {
                width: 100%;
                height: auto;
                margin-right: 20px;
                @include md {
                  width: 125px;
                }
                > svg {
                  width: 100%;
                  min-width: 125px;
                  // height: 15px;
                }
              }
              .type {
                font-family: $head-font-stack;
                font-size: 2rem;
                font-weight: 300;
                margin: 0;
              }
              .name {
                font-weight: 700;
                font-size: 1.4rem;
              }
            }
            .description {
              p {
                margin-bottom: 25px;
                font-weight: 300 !important;
                font-size: 1.4rem !important;
                font-weight: 300 !important;
              }
            }
            .launch {
              .primary-btn {
                // width: 270px;
                text-align: center;
              }
            }
            .divider {
              width: 100%;
              height: 1px;
              background: $primary-black;
            }
            .fullWidth {
              width: 100%;
              margin-bottom: 25px;
            }
          }
          .module {
            h1 {
              font-family: $head-font-stack;
              font-weight: 300;
              margin-bottom: 12px;
              margin-top: 0px;
              font-size: 2rem;
            }
            .description {
              font-family: $text-font-stack;
              font-weight: 300;
              font-size: 1.4rem;
              p {
                font-weight: 300 !important;
              }
            }
          }
        }
      }
    }
    .right {
      width: 10%;
      background: $primary-white;
      // height: 100%;
      min-height: calc(100vh - 93px);
      transition: width 0.3s ease-in-out;
      box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.29);
      @include lg {
        width: 100px;
      }
      &.mobile {
        width: 40px;
        // transition: width 0.3s ease-in-out;
        &.opened {
          width: 100%;
          // transition: width 0.3s ease-in-out;
        }
      }
      h1 {
        font-family: $head-font-stack;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 0px;
        font-size: 1.2rem;
      }
      .description {
        font-weight: 300;
        font-size: 1.2rem;
        margin-top: 0px;
        margin-bottom: 15px;
        &.invalid {
          color: $danger;
        }
        p {
          font-weight: 300 !important;
        }
      }
      > * {
        margin-left: 25px;
        margin-right: 25px;
      }
      &.opened {
        width: 40%;
        transition: width 0.3s ease-in-out;
      }
      .title {
        font-size: 2.4rem;
        margin-top: 18px;
        margin-bottom: 5px;
        font-weight: 300;
        font-family: $head-font-stack;
        display: flex;
        align-items: center;
        .svg {
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          > svg {
            margin-bottom: -7px;
            width: 15px;
            height: 15px;
            transform: rotate(-90deg);
            cursor: pointer;
          }
        }
      }
      .type {
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 30px;
        &.bright {
          color: $primary-bright;
        }
        &.red {
          color: $danger;
        }
      }
      > .svg {
        // width: 50px;
        margin: 50px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
          width: 15px;
          height: 8px;
          transform: rotate(90deg);
          cursor: pointer;
        }
      }
      .equipment {
        ul {
          list-style: none;
          padding: 0;
        }
        li {
          margin-bottom: 15px;
        }
        .preferred {
          background: $primary-dark;
          color: $primary-white;
          margin-left: 2px;
          padding: 2px 10px;
          border-radius: 15px;
          text-transform: uppercase;
        }
      }
    }
  }
  .notes {
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;

    .notes-inner {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;
      background: $grey;
      &.desktop {
        width: 100vw;
        padding-bottom: 50px;

        .notes-inner-top {
          padding-top: 35px;
          width: calc(100% - 100px);
        }
        .notes-inner-bottom {
          display: flex;
          width: calc(100% - 100px);

          .wysiwyg {
            flex: 2;
            margin-right: 15px;

            > p {
              position: absolute;
            }
          }
        }
        .assets {
          margin-top: 15px;
          margin-left: 5px;
        }
      }
      &.tablet {
        width: 70vw;

        .notes-inner-bottom {
          display: flex;

          .wysiwyg {
            margin-right: 5px;

            > p {
              position: absolute;
            }
          }
        }
        .assets {
          margin-top: 15px;
          margin-left: 5px;
        }
      }
      &.mobile {
        width: 30vw;
        &.opened {
          height: 460px !important;
        }
      }
      &.opened {
        height: 228px;
        .notes-inner-bottom {
          max-height: 90vh;
        }
      }
      .notes-inner-top {
        width: calc(100% - 50px);
        margin: 0 auto;
        padding: 25px 0 0;
        display: flex;
        align-items: center;
        .meta {
          flex: 9;
          font-family: $head-font-stack;
          font-size: 1.4rem;
          font-weight: 700;
          // margin-left: 50px;
        }
        svg {
          width: 20px;
          height: 10px;
          // margin-right: 50px;
          cursor: pointer;
          // padding-right: 50px;
          transform: rotate(180deg);
          transition: transform 0.3s ease-in-out;
          &.opened {
            transform: rotate(0deg);
          }
        }
      }
      .notes-inner-bottom {
        width: calc(100% - 50px);
        margin: 0 auto;
        // display: flex;
        align-items: stretch;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease-in-out;

        .wysiwyg {
          flex: 1;
          font-family: $head-font-stack;
          font-size: 1.4rem;
          font-weight: 700;
          padding-top: 15px;
          pointer-events: none;
          ::v-deep {
            // > div {
            //   height: 100%;
            //   > div {
            //     height: 100%;
            //   }
            // }
            // .cke_inner {
            //   height: 100%;
            // }
            .cke_contents {
              // height: calc(100% - 70px) !important;
              font-weight: 300 !important;
              color: rgba(0, 0, 0, 0.5) !important;
            }
          }
          > p {
            font-size: 10px;
            font-weight: 300;
            margin: 5px 0 15px;
          }
        }
        .assets {
          flex: 1;
          background: $primary-white;
          padding-bottom: 15px;

          h3 {
            font-family: $head-font-stack;
            font-size: 1rem;
            font-weight: 700;
            margin: 0;
            padding: 15px 25px 15px 25px;
          }
          p {
            margin: 0;
            padding: 0 25px 0 25px;
            font-weight: 300;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
.italic {
  font-style: italic;
}
.toolkit-info {
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    > div {
      margin-right: 50px;
    }
  }
  h1 {
    font-family: $head-font-stack;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 1.2rem;
  }
  .description {
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 15px;
    &.invalid {
      color: $danger;
    }
    p {
      font-weight: 300 !important;
    }
  }
  > * {
    @include md {
      margin-left: 25px;
    }
    margin-right: 25px;
  }
  &.opened {
    width: 40%;
    transition: width 0.3s ease-in-out;
  }
  .title {
    font-size: 2.4rem;
    margin-top: 18px;
    margin-bottom: 5px;
    font-weight: 300;
    font-family: $head-font-stack;
    display: flex;
    align-items: center;
    .svg {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      > svg {
        margin-bottom: -7px;
        width: 15px;
        height: 15px;
        transform: rotate(-90deg);
        cursor: pointer;
      }
    }
  }
  .type {
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 30px;
    &.bright {
      color: $primary-bright;
    }
    &.red {
      color: $danger;
    }
  }
  > .svg {
    // width: 50px;
    margin: 50px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 15px;
      height: 8px;
      transform: rotate(90deg);
      cursor: pointer;
    }
  }
  .equipment {
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      margin-bottom: 15px;
    }
    .preferred {
      background: $primary-dark;
      color: $primary-white;
      margin-left: 2px;
      padding: 2px 10px;
      border-radius: 15px;
      text-transform: uppercase;
    }
  }
}

.module {
  &.toolkit {
    border-bottom: 2px solid $danger;
    font-size: 1.4rem;
    display: flex;
    height: 56px;
    align-items: center;
  }
  font-family: $head-font-stack;
  font-weight: 300;
  font-size: 1.2rem;
  cursor: pointer;
  min-height: 35px;
  // display: flex;
  align-items: center;
  &.active {
    background: rgba(0, 176, 240, 0.4);
    font-weight: 700;
  }
  > span {
    padding: 10px 20px;
    width: calc(100% - 40px);
    // word-wrap: break-word;
  }
}

::v-deep {
  .description {
    p {
      margin-bottom: 25px;
      font-weight: 300 !important;
      font-size: 1.4rem !important;
      font-weight: 300 !important;
    }
  }
}
</style>
